import { SearchRounded } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  Backdrop,
  createTheme,
  ThemeProvider,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useSearchParams } from "@remix-run/react";
import { useMemo, useRef, useState } from "react";
import { useProducts } from "~/hooks";
import ProductCard from "./ProductCard";
import { Carousel } from "./Carousel";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const SEARCH_KEY = "search";

interface SearchProps {
  listItem?: boolean;
}

export const Search = ({ listItem }: SearchProps) => {
  const products = useProducts();

  const searchRef = useRef<HTMLInputElement>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get(SEARCH_KEY) || "");
  const setSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    const params = new URLSearchParams();
    params.set(SEARCH_KEY, e.target.value);
    setSearchParams(params, {
      preventScrollReset: true,
    });
  };

  const [open, setOpen] = useState(false);
  const openSearch = () => {
    setOpen(true);
    searchRef.current?.focus();
  };
  const closeSearch = () => {
    setOpen(false);
  };

  const searchResults = useMemo(() => {
    if (!search) {
      return [];
    }

    const res = products.filter(
      (p) =>
        p.visibility === "visible" &&
        p.name.toLowerCase().includes(search.toLowerCase())
    );
    return res;
  }, [products, search]);

  return (
    <>
      {listItem ? (
        <ListItem onClick={openSearch}>
          <ListItemIcon>
            <SearchRounded />
          </ListItemIcon>
          <ListItemText>Search</ListItemText>
        </ListItem>
      ) : (
        <IconButton onClick={openSearch}>
          <SearchRounded />
        </IconButton>
      )}

      <ThemeProvider theme={darkTheme}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
          open={open}
          onClick={closeSearch}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              inputRef={searchRef}
              value={search}
              onChange={setSearchQuery}
              placeholder="Search..."
              sx={{ marginBottom: (t) => t.spacing(2) }}
              // Prevent dismissing on click
              onClick={(e) => e.stopPropagation()}
            />

            <Box
              sx={{ margin: (t) => t.spacing(4) }}
              // We need to stop propagation because clicking on the navigation arrows in the carousel would dismiss the overlay.
              onClick={(e) => e.stopPropagation()}
            >
              <Carousel
                itemsPerPage={{ xs: 1, md: 3, lg: 4 }}
                styles={{ width: "90vw" }}
              >
                {searchResults.map((p) => (
                  <Box key={p.id} onClick={closeSearch}>
                    <ProductCard disableInteraction product={p} />
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Backdrop>
      </ThemeProvider>
    </>
  );
};
