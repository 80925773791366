import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { SwiperProps, SwiperRef } from "swiper/react";
import "node_modules/swiper/swiper.min.css";
import "node_modules/swiper/modules/navigation.min.css";
import "node_modules/swiper/modules/pagination.min.css";

interface CarouselProps {
  readonly children: React.ReactElement[];
  readonly itemsPerPage:
    | number
    | {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
      };
  readonly left?: boolean;
  readonly styles?: React.CSSProperties;
}

export const Carousel = ({
  children,
  itemsPerPage,
  left,
  styles,
}: CarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevPageRef = useRef<HTMLDivElement>(null);
  const nextPageRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const tBreakpoints = theme.breakpoints.values;
  const notSmall = useMediaQuery(theme.breakpoints.up("xs"));

  const { length } = children;
  useEffect(() => {
    // swiper element
    const swiperEl = swiperRef.current;

    // swiper parameters
    const swiperParams: SwiperProps = {
      breakpoints: {},
      navigation: {
        prevEl: prevPageRef.current,
        nextEl: nextPageRef.current,
      },
      pagination: {
        el: paginationRef.current,
        clickable: true,
      },
      initialSlide: 0,
      centerInsufficientSlides: true,
    };

    if (typeof itemsPerPage === "object") {
      if (itemsPerPage.xs) {
        swiperParams.breakpoints![tBreakpoints.xs] = {
          slidesPerView: itemsPerPage.xs,
        };
      }
      if (itemsPerPage.sm) {
        swiperParams.breakpoints![tBreakpoints.sm] = {
          slidesPerView: itemsPerPage.sm,
        };
      }
      if (itemsPerPage.md) {
        swiperParams.breakpoints![tBreakpoints.md] = {
          slidesPerView: itemsPerPage.md,
        };
      }
      if (itemsPerPage.lg) {
        swiperParams.breakpoints![tBreakpoints.lg] = {
          slidesPerView: itemsPerPage.lg,
        };
      }
      if (itemsPerPage.xl) {
        swiperParams.breakpoints![tBreakpoints.xl] = {
          slidesPerView: itemsPerPage.xl,
        };
      }
    } else {
      swiperParams.slidesPerView = itemsPerPage;
      swiperParams.centeredSlides =
        left || length < itemsPerPage ? false : true;
      swiperParams.centerInsufficientSlides =
        left || length > itemsPerPage ? false : true;
    }

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperEl!, swiperParams);
    if (swiperEl?.swiper) {
      // The swiper is initialized
      swiperEl.swiper.emit("breakpoint");
      swiperEl.swiper.update();
      return;
    }

    // and now initialize it
    // @ts-expect-error - initialize is exposed by the Swiperjs Web Component which we use, the React style is deprecated and only used for some Typescript autofill.
    swiperEl!.initialize();
  }, [length, itemsPerPage, left, tBreakpoints, notSmall]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: (t) => t.spacing(1) }}
      >
        <div ref={prevPageRef}>
          {" "}
          <IconButton>
            <ChevronLeft />
          </IconButton>{" "}
        </div>
        <div style={{ width: "100%" }}>
          <swiper-container
            //@ts-expect-error - see the initialize comment
            ref={swiperRef}
            //@ts-expect-error - see the initialize comment
            init="false"
            style={{ display: "grid", ...(styles || {}) }}
          >
            {children.map((c, index) => (
              <swiper-slide key={`car-${index}`}>{c}</swiper-slide>
            ))}
          </swiper-container>
        </div>
        <div ref={nextPageRef}>
          <IconButton>
            <ChevronRight />
          </IconButton>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: (t) => t.spacing(2),
        }}
        ref={paginationRef}
      ></Box>
    </Box>
  );
};
