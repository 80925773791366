import { Box, Grid, Link, Typography } from "@mui/material";
import DtwImage from "./DtwImage";
import { useMainData, useTextColorForPrimaryBg } from "~/hooks";

export default function Footer() {
  const main = useMainData();
  const primaryBgTextColor = useTextColorForPrimaryBg();

  const { policies, socialLinks, photoLinks, logo, shortDescription, slogan } =
    main;

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={(t) => ({
          alignItems: "center",
          padding: t.spacing(2),
          backgroundColor: t.palette.primary.main,
        })}
      >
        <Grid item xs={12} md={2} display="flex" justifyContent="center">
          <DtwImage picture={logo} alt="Store logo" sx={{ maxWidth: 96 }} />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          display="flex"
          justifyContent="center"
          gap={1}
          flexDirection="column"
        >
          <Typography color={primaryBgTextColor} variant="body2">
            {slogan}
          </Typography>
          <Typography color={primaryBgTextColor} variant="body2">
            {shortDescription}
          </Typography>
          {socialLinks.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: (t) => t.spacing(2),
              }}
            >
              {socialLinks.map((sLink) => (
                <Box
                  sx={{
                    appearance: "none",
                    outline: "none",
                    border: 0,
                    background: "transparent",
                  }}
                  component="button"
                  key={`smedia-${sLink.type}`}
                >
                  <a href={sLink.href} target="_blank" rel="noreferrer">
                    <img
                      src={sLink.icon}
                      alt={sLink.type}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  </a>
                </Box>
              ))}
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          display="flex"
          justifyContent="center"
          style={{
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {policies.map((link, index) => (
            <Link
              sx={{ textDecoration: "underline" }}
              color={primaryBgTextColor}
              key={`footerlink-${index}`}
              href={`/policies/${link.href}`}
            >
              {link.text}
            </Link>
          ))}
        </Grid>
      </Grid>

      <Typography variant="body2" sx={{ m: (t) => t.spacing() }}>
        Powered by{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://dropthework.com"
        >
          DropTheWork
        </a>
      </Typography>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: (t) => t.spacing(1),
          gap: (t) => t.spacing(2),
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {photoLinks.map((pLink, index) => (
          <a
            href={pLink.href}
            target="_blank"
            rel="noreferrer"
            key={`footerphotolink-${index}`}
          >
            <DtwImage
              picture={pLink.picture}
              alt={pLink.alt}
              sx={{ maxWidth: 200 }}
            />
          </a>
        ))}
      </Box>
    </>
  );
}
